import { Link } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Image } from 'src/components/ui/Image'
import { useMobile } from 'src/hooks/useMobile'

function Page() {
  const { isMobile } = useMobile()

  const title = 'Estamos com problemas no nosso acampamento'
  const description =
    'Nosso servidor pode estar descansando. Por favor, tente novamente ou retorne mais tarde.'

  return (
    <>
      <GatsbySeo title={title} description={description} />
      <div className="flex flex-col justify-center items-center font-inter mx-4 mt-9 text-center sm:max-w-[628px] sm:mx-auto sm:mt-16">
        <span className="text-2xl font-medium mb-6 leading-8 text-restructure-primary sm:text-[40px] sm:leading-[48px]">
          {title}
        </span>
        <span className="text-tertiary mb-10 sm:text-lg sm:mb-12">
          {description}
        </span>

        <Link
          to="/"
          className="flex justify-center items-center bg-restructure-background-blue text-white font-semibold w-[208px] h-[48px] rounded-round sm:text-lg"
        >
          Página inicial
        </Link>
      </div>

      <div className="mt-[72px] mb-[120px]">
        <Image
          src={`https://decathlonstore.vtexassets.com/arquivos/img-page-500-${
            isMobile ? 'mobile' : 'desktop'
          }.jpg`}
          width={isMobile ? 360 : 1064}
          height={isMobile ? 392 : 490}
          className="w-full sm:w-auto sm:max-w-[1064px] sm:max-h-[490px] sm:m-auto"
        />
      </div>
    </>
  )
}

export default Page
